import React from 'react';
import _ from 'lodash';

import SearchableSelect from './SearchableSelect.jsx';
import TenderList from './TenderList.jsx';
import SubSearch from './SubSearch.jsx';
import PublisherFilter from './PublisherFilter.jsx';
import Toolbar from './Toolbar.jsx';

export default class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            tokens: [...props.defaultOptions],
            filters: [...props.defaultFilters],
            filterFromDate: null,
            filterToDate: null,
            filterDateType: 0,
            filterDefenceMinistry: false,
            IsSupplierRegistration: false,
            IsAuctionResults: false,
            IsFreeAuction: false,
            IsGeneral: false,
            IsTourMandatory: false,
            showBranchesSubSearch: false,
            showRegionFilter: false,
            showPublisherFilter: false,
            showClassificationFilter: false,
            showCostValueFilter: false,
            checkedTenders: [],
            isLoggedIn: props.isLoggedIn
        };

        this.listRegions = [];
        this.listPublishers = [];
        this.listClassifications = [];
        this.listCostValues = [];
        this.tenderList = null;

        this.setTenderListRef = element => {
            this.tenderList = element;
        };
    }

    componentDidMount() {
        console.log('Search: componentDidMount');

        const { listRegionsUrl, listPublishersUrl, listClassificationsUrl, listCostValueUrl } = this.props;

        fetch(listRegionsUrl)
            .then(response => response.json())
            .then(listRegions => { this.listRegions = listRegions; });

        fetch(listPublishersUrl)
            .then(response => response.json())
            .then(listPublishers => { this.listPublishers = listPublishers; });


        fetch(listClassificationsUrl)
            .then(response => response.json())
            .then(listClassifications => { this.listClassifications = listClassifications; });

        fetch(listCostValueUrl)
            .then(response => response.json())
            .then(listCostValue => { this.listCostValues = listCostValue; });
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('Search: componentDidUpdate');
    }

    handleCloseBranchesSubSearchClick(e) {
        this.setState({
            showBranchesSubSearch: false
        });
    }

    handleCloseRegionsFilterClick(e) {
        this.setState({
            showRegionFilter: false
        });
    }

    handleClosePublishersFilterClick(e) {
        this.setState({
            showPublisherFilter: false
        });
    }

    handleCloseClassificationsFilterClick(e) {
        this.setState({
            showClassificationFilter: false
        });
    }

    handleCloseCostValueFilterClick(e) {
        this.setState({
            showCostValueFilter: false
        });
    }

    handleChooseBranchesClick(e) {
        this.setState({
            showBranchesSubSearch: true
        });
    }

    handleClearClick(e) {
        this.setState({ tokens: [] });
    }

    handleChooseRegionsClick(e) {
        this.setState({
            showRegionFilter: true
        });
    }

    handleChoosePublishersClick(e) {
        this.setState({
            showPublisherFilter: true
        });
    }

    handleChooseClassificationsClick(e) {
        this.setState({
            showClassificationFilter: true
        });
    }

    handleChooseCostValueClick(e) {
        this.setState({
            showCostValueFilter: true
        });
    }



    // tokens: { auction: [], branch: [], publisher: [], region: [] }
    // tokens: [{label: 'עיריית דימונה', value: 164, typeLabel: 'מפרסם', typeValue: 'publisher'}, {label: 'עיריית רמלה', value: 3, typeLabel: 'מפרסם', typeValue: 'publisher'}]
    handleSelectChange(tokens) {
        this.setState({ tokens: tokens }, () => {
            const url = new URL(window.location);
            var jsonTokens = JSON.stringify(this.state.tokens);
            url.searchParams.set('token', encodeURIComponent(jsonTokens));
            console.log(url)
            window.history.pushState({}, '', url);
            //const element = document.getElementById('scrollTotenders');
            //const y = element.getBoundingClientRect().bottom + window.scrollY;
            //window.scroll({
            //    top: y,
            //    behavior: 'smooth'
            //});
            setTimeout(function () {
                const section = document.querySelector('#scrollTotenders');
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                console.log('scroll to element3');
            }, 500);

        });
    }

    // tokens: { branch: [] }
    // tokens: [{label: 'שיפוצים', value: 1, typeLabel: 'ענף', typeValue: 'branch'}, {label: 'מחשבים', value: 2, typeLabel: 'ענף', typeValue: 'branch'}, {label: 'ייעוץ וניהול', value: 3, typeLabel: 'ענף', typeValue: 'branch'}]
    handleBranchChange(tokens) {


        this.setState((prevState, props) => {
            return {
                //tokens: _.unionWith(prevState.tokens, tokens.map(x => ({ typeValue: 'branch', typeLabel: 'ענף', value: x.id, label: x.name })), (a, b) => a.typeValue === b.typeValue && a.value === b.value),
                tokens: prevState.tokens.filter(x => x.typeValue !== 'branch').concat(tokens.map(x => ({ label: x.name, value: x.id, typeLabel: 'ענף', typeValue: 'branch' }))),
                showBranchesSubSearch: false
            };
        }, () => {
            const url = new URL(window.location);
            var jsonTokens = JSON.stringify(this.state.tokens);
            url.searchParams.set('token', encodeURIComponent(jsonTokens));
            console.log(url)
            window.history.pushState({}, '', url);
        });
    }

    // filters: []
    handleChooseRegions(filters) {
        this.setState((prevState, props) => {
            return {
                filters: prevState.filters.filter(x => x.type !== 'region').concat(filters.map(x => ({ type: 'region', id: x.id, name: x.name }))),
                showRegionFilter: false
            }
        }, () => {
            const url = new URL(window.location);
            var jsonTokens = JSON.stringify(this.state.filters);
            url.searchParams.set('filter', encodeURIComponent(jsonTokens));
            console.log(url);
            window.history.pushState({}, '', url);
        })
    };

    // filters: []
    handleChoosePublishers(filters) {
        this.setState((prevState, props) => ({
            filters: prevState.filters.filter(x => x.type !== 'publisher').concat(filters.map(x => ({ type: 'publisher', id: x.id, name: x.name }))),
            showPublisherFilter: false
        }), () => {

            const url = new URL(window.location);
            var jsonTokens = JSON.stringify(this.state.filters);
            url.searchParams.set('filter', encodeURIComponent(jsonTokens));
            window.history.pushState({}, '', url);
        });
    }

    // filters: []
    handleChooseClassifications(filters) {
        this.setState((prevState, props) => ({
            filters: prevState.filters.filter(x => x.type !== 'classification').concat(filters.map(x => ({ type: 'classification', id: x.id, name: x.name }))),
            showClassificationFilter: false
        }), () => {
            const url = new URL(window.location);
            var jsonTokens = JSON.stringify(this.state.filters);
            url.searchParams.set('filter', encodeURIComponent(jsonTokens));
            window.history.pushState({}, '', url);
        });
    }

    // filters: []
    handleChooseCostValue(filters) {
        this.setState((prevState, props) => ({
            filters: prevState.filters.filter(x => x.type !== 'costValue').concat(filters.map(x => ({ type: 'costValue', id: x.id, name: x.name }))),
            showCostValueFilter: false
        }), () => {
            const url = new URL(window.location);
            var jsonTokens = JSON.stringify(this.state.filters);
            url.searchParams.set('filter', encodeURIComponent(jsonTokens));
            window.history.pushState({}, '', url);
        });
    }

    handleFromDateChange(e) {
        this.setState({ filterFromDate: e.target.value });
    }

    handleToDateChange(e) {
        this.setState({ filterToDate: e.target.value });
    }

    handleFilterDefenceMinistry(e) {
        this.setState({ filterDefenceMinistry: e.target.checked });
    }
    handleIsSupplierRegistration(e) {
        this.setState({ IsSupplierRegistration: e.target.checked });
    }
    handleIsAuctionResults(e) {
        this.setState({ IsAuctionResults: e.target.checked });
    }
    handleIsFreeAuction(e) {
        this.setState({ IsFreeAuction: e.target.checked });
    }
    handleIsGeneral(e) {
        this.setState({ IsGeneral: e.target.checked });
    }
    handleIsTourMandatory(e) {
        this.setState({ IsTourMandatory: e.target.checked });
    }

    handleChooseByDateTypeClick(dateType) {
        this.setState({ filterDateType: dateType });
    }

    handleChooseTenders(checkedTenders) {
        this.setState({ checkedTenders: checkedTenders })
    }

    handleAddCheckedToFavorite() {
        this.tenderList.handleAddToFavorite(this.state.checkedTenders.map(el => ({ id: el, isFavorite: true })), true);
    }

    render() {
        const checkedItems = this.state.tokens.filter(x => x.typeValue === 'branch').map(x => x.value);

        const freeDaysBanner = !this.props.isLoggedIn ?
            <a href="/register?durationId=18" className="free_days_banner" >
                <h2 className="title">
                    מצטרפים היום ונהנים מ 30 ימי נסיון ללא עלות וללא התחייבות
                </h2>
                <small className="note">
                    למצטרפים חדשים
                </small>
            </a> :
            null;

        const branchesSubSearch = this.state.showBranchesSubSearch ?
            <SubSearch
                title='בחירת ענפים'
                listUrl={this.props.listBranchesUrl}
                checkedItems={checkedItems}
                onCloseClick={this.handleCloseBranchesSubSearchClick.bind(this)}
                onSelectItems={this.handleBranchChange.bind(this)}
            />
            : null;

        const regionFilter = this.state.showRegionFilter ?
            <PublisherFilter
                title='בחירת מיקום'
                items={this.listRegions}
                checkedItems={this.state.filters.filter(x => x.type === 'region').map(x => x.id)}
                onCloseClick={this.handleCloseRegionsFilterClick.bind(this)}
                onSelectItems={this.handleChooseRegions.bind(this)}
            />
            : null;

        const selectedPublishers = this.state.tokens.filter(x => x.typeValue === 'publisher').map(x => x.value);
        const listPublishers = selectedPublishers.length > 0 ? this.listPublishers.filter(x => selectedPublishers.includes(x.id)) : this.listPublishers;
        const publisherFilter = this.state.showPublisherFilter ?
            <PublisherFilter
                title='בחירת מפרסמים'
                items={listPublishers}
                checkedItems={this.state.filters.filter(x => x.type === 'publisher').map(x => x.id)}
                onCloseClick={this.handleClosePublishersFilterClick.bind(this)}
                onSelectItems={this.handleChoosePublishers.bind(this)}
            />
            : null;

        const classificationFilter = this.state.showClassificationFilter ?
            <PublisherFilter
                title='בחירת סיווג קבלני'
                items={this.listClassifications}
                checkedItems={this.state.filters.filter(x => x.type === 'classification').map(x => x.id)}
                onCloseClick={this.handleCloseClassificationsFilterClick.bind(this)}
                onSelectItems={this.handleChooseClassifications.bind(this)}
            />
            : null;
        const costValueFilter = this.state.showCostValueFilter ?
            <PublisherFilter
                title=' עלות המסמכים '
                items={this.listCostValues}
                checkedItems={this.state.filters.filter(x => x.type === 'costValue').map(x => x.id)}
                onCloseClick={this.handleCloseCostValueFilterClick.bind(this)}
                onSelectItems={this.handleChooseCostValue.bind(this)}
            />
            : null;
        return (
            <section className="tenders_conti auto_margin2 item_margin_b_50">
                <div className="free_search_tenders form">
                    <ul className="flex flex_wrap flex_space_evenly">
                        <li>
                            <label>
                                <span role="button" className="link" onClick={this.handleChooseBranchesClick.bind(this)}> בחירת ענפים</span>
                            </label>

                            <SearchableSelect
                                searchUrl={this.props.searchUrl}
                                handleSelectChange={this.handleSelectChange.bind(this)}
                                selectedOption={this.state.tokens}
                                placeholder="בחר"
                                inputId="search" />
                        </li>
                        <li>
                            <button className="button transition search_button">
                                חיפוש
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="advanced_search form">
                    <h3 className="title">
                        סנן לפי
                    </h3>
                    <ul className="flex flex_wrap first_section">
                        <li onClick={this.handleChooseRegionsClick.bind(this)}>
                            <label>
                                <strong>
                                    מיקום:
                                </strong>
                                <span role="button" className="link">בחירת מיקום</span>
                            </label>
                            <input type="text" readOnly value={this.state.filters.filter(x => x.type === 'region').map(x => x.name).join(', ')} />
                        </li>
                        <li onClick={this.handleChoosePublishersClick.bind(this)}>
                            <label>
                                <strong>
                                    מפרסמים:
                                </strong>
                                <span role="button" className="link" >בחירת מפרסמים</span>
                            </label>
                            <div className="has_tooltip">
                                <input type="text" readOnly value={this.state.filters.filter(x => x.type === 'publisher').map(x => x.name).join(', ')} />
                                <span className="q_marq">
                                    <span>?</span>
                                </span>
                            </div>
                        </li>
                        <li onClick={this.handleChooseClassificationsClick.bind(this)}>
                            <label>
                                <strong>
                                    סיווג קבלני:
                                </strong>
                                <span role="button" className="link" > בחירת סיווג קבלני</span>
                            </label>
                            <div className="has_tooltip">
                                <input type="text" readOnly value={this.state.filters.filter(x => x.type === 'classification').map(x => x.name).join(', ')} />
                                <span className="q_marq">
                                    <span>?</span>
                                </span>
                            </div>
                        </li>
                        <li onClick={this.handleChooseCostValueClick.bind(this)}>
                            <label>
                                <strong>
                                    עלות מסמכים:
                                </strong>
                                <span role="button" className="link" > בחירת עלות מכרז</span>
                            </label>
                            <div className="has_tooltip">
                                <input type="text" readOnly value={this.state.filters.filter(x => x.type === 'costValue').map(x => x.name).join(', ')} />
                                <span className="q_marq">
                                    <span>?</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div className="show_advanced_search">
                        <a className="transition">
                            חיפוש מתקדם
                        </a>
                    </div>
                    <div className="flex flex_wrap advanced_search_secound_section">
                        <div className="by_publisher_or_submission">
                            <ul className="flex">
                                <li>
                                    <span onClick={() => { this.handleChooseByDateTypeClick(0) }}>
                                        תאריך פרסום
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => { this.handleChooseByDateTypeClick(1) }}>
                                        תאריך הגשה
                                    </span>
                                </li>                              

                            </ul>
                            <span className={`border transition ${this.state.filterDateType % 2 === 1 ? 'slide' : null}`}></span>
                        </div>

                        <ul className="flex flex_wrap secound_section">
                            <li>
                                <div className="has_label active">
                                    <input type="date" onChange={this.handleFromDateChange.bind(this)} />
                                    <label className="transition small_label input">
                                        <span>
                                            מ -
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className="has_label active">
                                    <input type="date" onChange={this.handleToDateChange.bind(this)} />
                                    <label className="transition small_label active">
                                        <span>
                                            עד
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex_wrap">
                        <div className="checkboxes_items">
                            <ul className="flex">                              
                                <li>
                                    <div className="checkbox_conti3">
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={this.state.filterDefenceMinistry}
                                                onChange={this.handleFilterDefenceMinistry.bind(this)} />
                                            <span></span>
                                        </div>
                                        <label> ללא משרד הבטחון</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="checkbox_conti3">

                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={this.state.IsGeneral}
                                                onChange={this.handleIsGeneral.bind(this)} />
                                            <span></span>
                                        </div>
                                        <label> כללי</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="checkbox_conti3">

                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={this.state.IsAuctionResults}
                                                onChange={this.handleIsAuctionResults.bind(this)} />
                                            <span></span>
                                        </div>
                                        <label> תוצאות מכרזים</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="checkbox_conti3">
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={this.state.IsFreeAuction}
                                                onChange={this.handleIsFreeAuction.bind(this)} />
                                            <span></span>
                                        </div>
                                        <label> פטור ממכרז</label>
                                    </div>

                                </li>

                                <li>
                                    <div className="checkbox_conti3">
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={this.state.IsSupplierRegistration}
                                                onChange={this.handleIsSupplierRegistration.bind(this)} />
                                            <span></span>
                                        </div>
                                        <label> רישום ספקים</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="checkbox_conti3">
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={this.state.IsTourMandatory}
                                                onChange={this.handleIsTourMandatory.bind(this)} />
                                            <span></span>
                                        </div>
                                        <label>סיור חובה</label>
                                    </div>

                                </li>

                            </ul>                          
                        </div>                        
                    </div>
                    <label>
                        <span role="button" className="link" onClick={this.handleClearClick.bind(this)}>נקה חיפוש</span>
                    </label>
                </div>
                {freeDaysBanner}

                <div id="scrollTotenders"></div>
                <TenderList
                    listUrl={this.props.tenderlistUrl}
                    addToFavoriteUrl={this.props.addToFavoriteUrl}
                    addOrUpdateReminderUrl={this.props.addOrUpdateReminderUrl}
                    tenderReminderUrl={this.props.tenderReminderUrl}
                    tokens={this.state.tokens}
                    filters={this.state.filters}
                    filterFromDate={this.state.filterFromDate}
                    filterToDate={this.state.filterToDate}
                    filterDateType={this.state.filterDateType}
                    filterDefenceMinistry={this.state.filterDefenceMinistry}
                    IsSupplierRegistration={this.state.IsSupplierRegistration}
                    IsAuctionResults={this.state.IsAuctionResults}
                    IsFreeAuction={this.state.IsFreeAuction}
                    IsTourMandatory={this.state.IsTourMandatory}
                    IsGeneral={this.state.IsGeneral}
                    handleChooseTenders={this.handleChooseTenders.bind(this)}
                    isPagging={true}
                    ref={this.setTenderListRef} />

                {branchesSubSearch}
                {regionFilter}
                {publisherFilter}
                {classificationFilter}
                {costValueFilter}

                <Toolbar
                    sendEmailUrl={this.props.sendEmailUrl}
                    userEmail={this.props.userEmail}
                    checkedTenders={this.state.checkedTenders}
                    handleAddCheckedToFavorite={this.handleAddCheckedToFavorite.bind(this)} />
            </section>
        );
    }
}