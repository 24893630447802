import React from 'react';

import moment from 'moment';
import 'moment/locale/he';

export default class Reminder extends React.Component {
    constructor(props) {
        super(props);

        moment.locale('he');

        this.state = {
        }
    }

    render() {
        let reminderDate = moment(this.props.date);
        return (
            <li>
                <div className="reminder_item">
                    <div className="reminder_options">
                        <ul>
                            <li>
                                <a href="#" title="מחק" onClick={() => window.confirm('האם למחוק פריט זה?') && this.props.handleRemoveReminderClick(this.props.tenderId)}>
                                    <img src="/Content/Resources/General/delete.png" alt="מחק" />
                                </a>
                            </li>
                            <li>
                                <a href="#" title="עריכת תזכורת" onClick={() => this.props.handleEditReminderClick(this.props.tenderId)}>
                                    <img src="/Content/Resources/General/edit2.png" alt="עריכת תזכורת" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <strong className="subject">
                        נושא התזכורת
                    </strong>
                    <p className="subject_content">
                        {this.props.title}
                    </p>
                    <ul className="content flex flex_wrap">
                        <li>
                            <strong>
                                תאריך:
                            </strong>
                            <span>
                                {reminderDate.format('L')}
                            </span>
                        </li>
                        <li>
                            <strong>
                                שעה:
                            </strong>
                            <span>
                                {reminderDate.format('HH:mm')}
                            </span>
                        </li>
                        <li>
                            <strong>
                                מייל:
                            </strong>
                            <span>
                                {this.props.email}
                            </span>
                        </li>
                       {/* <li>
                            <strong>
                                נייד לקבלת הודעה:
                            </strong>
                            <span>
                                {this.props.phone}
                            </span>
                        </li>*/}
                    </ul>
                    <strong className="subject">
                        הערה
                    </strong>
                    <small className="reminder_note">
                        {this.props.comment}
                    </small>
                </div>
            </li>
        );
    }
}