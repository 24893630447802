import React from 'react';

import Reminder from './Reminder.jsx';
import TenderReminder from './TenderReminder.jsx';

export default class ReminderList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            reminders: [],
            loading: true,
            showEditReminder: false
        };
    }

    componentDidMount() {
        this.fetchReminders();
    }

    handleCloseEditReminderClick(e) {
        this.setState({
            showEditReminder: false
        });
    }

    handleEditReminderClick(id) {
        this.setState({
            showEditReminder: true,
            reminderTenderId: id
        });
    }

    handleAddOrUpdateReminder(reminder) {
        const { addOrUpdateReminderUrl } = this.props;

        fetch(addOrUpdateReminderUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(reminder)
        })
            .then(response => {
                if (response.ok) {
                    this.setState((prevState, props) => ({
                        reminders: prevState.reminders.map(el => el.tenderId === reminder.tenderId ? { ...el, ...reminder } : el),
                        showEditReminder: false
                    }));
                }
            })
            .catch(error => { console.log(error) });
    }

    handleRemoveReminderClick(tenderId) {
        const { removeReminderUrl } = this.props;

        fetch(removeReminderUrl + '?tenderId=' + tenderId, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        })
            .then(response => {
                if (response.ok) {
                    this.setState((prevState, props) => ({
                        reminders: prevState.reminders.filter(el => el.tenderId !== tenderId)
                    }));
                }
            })
            .catch(error => { console.log(error) });
    }

    fetchReminders() {
        const { listUrl } = this.props;

        fetch(listUrl)
            .then(response => response.json())
            .then(
                data => {
                    this.setState((prevState, props) => ({
                        reminders: data,
                        loading: false
                    }));
                },
                error => {
                    this.setState({
                        error,
                        loading: false
                    });
                });
    }

    renderReminderList(reminders) {
        return reminders.map(reminder =>
            <Reminder
                key={reminder.tenderId}
                {...reminder}
                handleEditReminderClick={this.handleEditReminderClick.bind(this)}
                handleRemoveReminderClick={this.handleRemoveReminderClick.bind(this)}
            />
        );
    }

    render() {
        let contents = this.state.loading
            ? <p>Loading...</p>
            : contents = this.renderReminderList(this.state.reminders);

        let tenderReminder = this.state.showEditReminder ?
            <TenderReminder
                tenderId={this.state.reminderTenderId}
                tenderReminderUrl={this.props.tenderReminderUrl}
                handleCloseClick={this.handleCloseEditReminderClick.bind(this)}
                handleAddOrUpdateReminder={this.handleAddOrUpdateReminder.bind(this)} />
            : null;

        return (
            <>
                <div className="reminders_list">
                    <ul>
                        {contents}
                    </ul>
                </div>
                {tenderReminder}
            </>
        );
    }
}