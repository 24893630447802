import React from 'react';

export default class AuctionActions extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={`shared_options transition ${this.props.auctions.length > 0 ? "active" : ""}`}>
                <div className="flex flex_space_center flex_wrap">
                    <strong>
                        {this.props.auctions.length} מכרזים נבחרו
                    </strong>
                    <ul>
                        <li>
                            <a href="#" className="fav" onClick={(e) => { this.props.isLoggedIn ? this.props.addToFavorites(e) : showNotLoggedInPopup() }}>
                                <div>
                                    <img src="/Content/Resources/General/star.svg" alt="הוספה למועדפים" />
                                </div>
                                <span>
                                    הוספה למועדפים
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="print" onClick={(e) => { this.props.isLoggedIn ? this.props.sendEmails(e) : showNotLoggedInPopup() }}>
                                <div>
                                    <img src="/Content/Resources/General/sendEmail.svg" alt="שלח במייל" />
                                </div>
                                <span>
                                    שלח במייל
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:window.print()">

                                <div>
                                    <img src="/Content/Resources/General/print.svg" alt="הדפסה" />
                                </div>
                                <span>
                                    הדפסה
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
};