import React from 'react';

import AuctionFavorite from './AuctionFavorite.jsx';

export default class AuctionItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let submissionTimeContent;
        let tourMandatoryContent;
        
        const title = this.props.item.Title;
        const createdAt = this.props.item.CreatedAt;
        const submissionDateTime = this.props.item.SubmissionDateTime;
        const id = this.props.item.Id;
        const url = this.props.auctionUrl + "/" + id;
        const isTourMandatory = this.props.item.IsTourMandatory;
        const publisherName = this.props.item.PublisherName;

        if (submissionDateTime !== "" ) {   
            submissionTimeContent = <li className="due_date dot_after">
                מועד הגשה  {submissionDateTime}
            </li>
        }
        if (isTourMandatory === true) {
            tourMandatoryContent = <span className="must">
                סיור חובה
            </span>
        }

        return (
            <div className="tender_item">
                <div className="flex flex_wrap tender_detials">
                    <div className="flex flex_items_align_middle tender_item_checkbox_conti flex_space_center">
                        <div className="tender_item_checkbox checkbox_conti3">
                            <div>
                                <input type="checkbox" name="name" defaultChecked={this.props.isChecked} value={this.props.isChecked} onChange={(e) => { this.props.checkboxHandle(e.target.checked, this.props.item.Id) }} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <a href={url} className="title transition">
                            {title}
                        </a>
                        <ul className="flex flex_wrap fav_and_reminder">
                            <AuctionFavorite
                                favorite={this.props.favorite}
                                addItemToFavorites={this.props.addItemToFavorites}
                                isLoggedIn={this.props.isLoggedIn}
                                itemId={this.props.item.Id}
                            />
                            <li>
                                <a href="#" className={this.props.reminder ? "reminder active" : "reminder"} onClick={(e) => { e.preventDefault(); this.props.isLoggedIn ? this.props.showReminderForm(this.props.item.Id) : showNotLoggedInPopup() }}>
                                    <div>
                                        <img src="/Content/Resources/TenderIcons/bell.svg" className="bell transition" alt="הוספת תזכורת" />
                                        <img src="/Content/Resources/TenderIcons/bellActive.svg" className="bell_active transition" alt="הוספת תזכורת" />
                                    </div>
                                    <span>
                                        הוספת תזכורת
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <a href={url} className="tender_arrow transition" title="צפה במכרז">
                        <span className="arrow arrow_left"></span>
                    </a>
                </div>
                <div className="tender_sub_details">
                    <ul className="flex flex_wrap">
                        <li className="publish_date dot_after">
                            פורסם {createdAt}
                        </li>
                        {submissionTimeContent}
                        <li className="publisher">
                            מפרסם
                        <span>
                                {publisherName}
                            </span>
                        </li>
                    </ul>
                    {tourMandatoryContent}
                </div>
            </div>
        );
    }
}
