import React from 'react';

import moment from 'moment';
import 'moment/locale/he';

import { isEmpty, pattern } from './utils.jsx';

export default class TenderReminder extends React.Component {
    constructor(props) {
        super(props);

        moment.locale('he');

        this.state = {
            fields: {},
            errors: {},
            reminder: {},
            title: 'הוספת',
            error: null,
            loading: true
        }

        const { tenderReminderUrl, tenderId } = this.props;

        fetch(tenderReminderUrl + '?id=' + tenderId)
            .then(response => response.json())
            .then(
                data => {
                    
                    let { date, ...reminder } = data;
                    reminder.reminderDate = date != null ? moment(date).format("YYYY-MM-DD") : '';
                    reminder.reminderTime = date != null ? moment(date).format("HH:mm") : '';
                    this.setState({
                        reminder: reminder,
                        title: isEmpty(data.userId) ? 'הוספת' : 'עריכת',
                        loading: false
                    });
                },
                error => {
                    this.setState({
                        error,
                        loading: false
                    });
                });
    }

    handleValidation() {
        let { reminder } = this.state;
        let errors = {};
        let formIsValid = true;

        if (isEmpty(reminder['title'])) {
            formIsValid = false;
            errors['title'] = 'שדה חובה';
        }

        if (isEmpty(reminder['reminderDate'])) {
            formIsValid = false;
            errors['reminderDate'] = 'שדה חובה';
        }

        if (isEmpty(reminder['reminderTime'])) {
            formIsValid = false;
            errors['reminderTime'] = 'שדה חובה';
        }

        if (isEmpty(reminder['phone']) && isEmpty(reminder['email'])) {
            formIsValid = false;
            errors['email'] = 'יש להזין או אימייל או טלפון';
        } else {
            if (!isEmpty(reminder['phone'])) {
                if (!pattern.phone.test(reminder['phone'])) {
                    formIsValid = false;
                    errors['phone'] = 'יש להזין רק ספרות';
                }
            }

            if (!isEmpty(reminder['email'])) {
                if (!pattern.email.test(reminder['email'])) {
                    formIsValid = false;
                    errors['email'] = 'אימייל לא תקין';
                }
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    reminderSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            let { reminder } = this.state;
            reminder.date = moment(reminder.reminderDate + ' ' + reminder.reminderTime).format('YYYY-MM-DD[T]HH:mm');
            this.props.handleAddOrUpdateReminder(reminder);
        }
    }

    handleChange(field, e) {
        const value = e.target.value;
        this.setState((prevState, props) => ({
            reminder: { ...prevState.reminder, [field]: value }
        }));
    }

    render() {
        return (
            <div className="popup_conti add_reminder_popup" style={{ display: "block" }}>
                <div className="popup form">
                    <a className="close_popup" onClick={this.props.handleCloseClick}>X</a>
                    <div className="popup_headline text_center item_margin_b_30">
                        <h2>
                            {this.state.title} תזכורת
                        </h2>
                    </div>
                    <form onSubmit={this.reminderSubmit.bind(this)}>
                        <ul className="flex flex_wrap first_section">
                            <li>
                                <label>
                                    נושא התזכורת
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    onChange={this.handleChange.bind(this, "title")}
                                    value={this.state.reminder["title"] || ''} />
                                <span>{this.state.errors["title"]}</span>
                            </li>
                        </ul>
                        <ul className="flex flex_space_evenly flex_wrap secound_section">
                            <li>
                                <label>
                                    תאריך
                                </label>
                                <input
                                    min={new Date().toISOString().slice(0, -14)}
                                    type="date"
                                    name="reminderDate"
                                    onChange={this.handleChange.bind(this, "reminderDate")}
                                    value={this.state.reminder["reminderDate"] || ''} />
                                <span>{this.state.errors["reminderDate"]}</span>
                            </li>
                            <li>
                                <label>
                                    שעה
                                </label>
                                <input
                                    type="time"
                                    name="reminderTime"
                                    onChange={this.handleChange.bind(this, "reminderTime")}
                                    value={this.state.reminder["reminderTime"] || ''} />
                                <span>{this.state.errors["reminderTime"]}</span>
                            </li>
                        </ul>
                        <ul className="flex flex_wrap first_section">
                            <li>
                                <label>
                                    מייל
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    onChange={this.handleChange.bind(this, "email")}
                                    value={this.state.reminder["email"] || ''} />
                                <span>{this.state.errors["email"]}</span>
                            </li>
                           {/* <li>
                                <label>
                                    נייד לקבלת הודעה
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    onChange={this.handleChange.bind(this, "phone")}
                                    value={this.state.reminder["phone"] || ''} />
                                <span>{this.state.errors["phone"]}</span>
                            </li>*/}
                        </ul>
                        <ul className="flex flex_wrap first_section">
                            <li>
                                <label>
                                    הערה
                                </label>
                                <input
                                    type="text"
                                    name="comment"
                                    onChange={this.handleChange.bind(this, "comment")}
                                    value={this.state.reminder["comment"] || ''} />
                                <span>{this.state.errors["comment"]}</span>
                            </li>
                        </ul>
                        <ul className="flex flex_wrap third_section">
                            <li>
                                <button className="button button2 transition">
                                    שמור
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        );
    }
};