// Content/components/expose-components.js

import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { ServerStyleSheet } from 'styled-components';

import Search from './React/Search.jsx';
import StaticSearch from './React/StaticSearch.jsx';
import Auctions from './React/Auctions.jsx';
import ReminderList from './React/ReminderList.jsx';
import TenderPageReminder from './React/TenderPageReminder.jsx';

// any css-in-js or other libraries you want to use server-side
import Helmet from 'react-helmet';
//import Select from 'react-select';

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.Styled = { ServerStyleSheet };
global.Helmet = Helmet;
//global.Select = Select;

global.Components = { Search, StaticSearch, Auctions, ReminderList, TenderPageReminder };