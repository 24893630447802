import React from 'react';

export default class Tender extends React.Component {

    constructor(props) {
        super(props);
    }
    renderBanner() {
        return this.props.showBanner && this.props.publisherName != null ?
           <div className="cta_buttons2 tender_item">
                <a href="/Lp/Lp1">
                    <img src="/Content/Resources/Lp/Contractor'sLicense1.jpg" />
                </a>

            </div> : "";
    }
    handleAddToFavorite = (id, isFavorite) => {
        if (this.props.publisherName != null) {

            const addToFavorites = [
                { id: id, isFavorite: isFavorite },
                // Add more objects as needed
            ];
            this.props.handleAddToFavorite(addToFavorites);
        } else {
            // User is not logged in, show a message or redirect to login
            $("[data-notLoggenInPopup]").fadeIn(200);
        }
    };
    render() {
        return (
            <div className="tender_item">
                <div className="flex flex_wrap tender_detials">
                    <div className="flex flex_items_align_middle tender_item_checkbox_conti flex_space_center">
                        <div className="tender_item_checkbox checkbox_conti3">
                            <div>
                                <input type="checkbox"
                                    onChange={this.props.handleCheckboxChangeClick}
                                    checked={this.props.isChecked}
                                    value={this.props.id} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <a href={`/tender/${this.props.id}`} className="title transition" title="צפה במכרז" target="_blank">
                            <h2>
                                {this.props.title}
                            </h2>
                        </a>
                        <ul className="flex flex_wrap fav_and_reminder">
                            <li>
                                <a className={`fav ${this.props.isFavorite ? ' active' : ''}`} onClick={() => this.handleAddToFavorite(this.props.id, !this.props.isFavorite)}>
                                    <div>
                                        <img src="/Content/Resources/TenderIcons/star.svg" alt="הוספה למועדפים" className="star transition" />
                                        <img src="/Content/Resources/TenderIcons/starActive.svg" alt="הוספה למועדפים" className="star_active transition" />
                                    </div>
                                    <span>
                                        הוספה למועדפים
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a className={`reminder ${this.props.hasReminder ? ' active' : ''}`} onClick={() => this.props.handleEditReminderClick(this.props.id)}>
                                    <div>
                                        <img src="/Content/Resources/TenderIcons/bell.svg" className="bell transition" alt="הוספת תזכורת" />
                                        <img src="/Content/Resources/TenderIcons/bellActive.svg" className="bell_active transition" alt="הוספת תזכורת" />
                                    </div>
                                    <span>
                                        הוספת תזכורת
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <a href={`/tender/${this.props.id}`} className="tender_arrow transition" title="צפה במכרז">
                        <span className="arrow arrow_left"></span>
                    </a>
                </div>
                <div className="tender_sub_details">
                    <ul className="flex flex_wrap">
                        <li className="publish_date dot_after">
                            פורסם בתאריך {this.props.publishingDate}
                        </li>

                        {this.props.submissionDate != null && <li className="due_date dot_after">
                            <span>מועד הגשה {this.props.submissionDate}</span>
                        </li>
                        }
                        <li className="publisher">
                            {this.props.publisherName != null &&
                                <span>מפרסם {this.props.publisherName}</span>

                            }
                            {this.props.publisherName == null &&
                                <span> מפרסם הפריט זמין למנויים בלבד</span>
                            }
                        </li>
                    </ul>
                    {this.props.isTourMandatory &&
                        <span className="must">
                            סיור חובה
                        </span>
                    }
                </div>
                {this.renderBanner()}
            </div>
        );
    }
}