import React from 'react';

export default class Toolbar extends React.Component {

    constructor(props) {
        super(props);
    }

    handleAddCheckedToFavorite = () => {
        if (this.props.userEmail != null) {

            this.props.handleAddCheckedToFavorite();
        } else {
            // User is not logged in, show a message or redirect to login
            $("[data-notLoggenInPopup]").fadeIn(200);
        }
    };

    handleSendEmail(e) {
        const { sendEmailUrl } = this.props;
        if (this.props.userEmail != null) {

        
        fetch(sendEmailUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify( this.props.checkedTenders )
        })
            .then(response => {
                if (response.ok) {
                    alert("מכרז נשלח למייל " + this.props.userEmail);
                }
            })
            .catch(error => {
                if (this.props.userEmail != null) {
                    alert("שגיאה בשליחת קובץ זה למייל")
                }
                
            });
        }
        else {
            $("[data-notLoggenInPopup]").fadeIn(200);

        }
    }

    render() {
        const toolbarClasses = "shared_options transition" + (this.props.checkedTenders.length > 0 && " active");

        return (
            <div className={toolbarClasses}>
                <div className="flex flex_space_center flex_wrap">
                    <strong>
                        {this.props.checkedTenders.length} מכרזים נבחרו
                    </strong>
                    <ul>
                        <li>
                            <span className="fav" onClick={this.handleAddCheckedToFavorite.bind(this)} >
                                <div>
                                    <img src="/Content/Resources/General/star.svg" alt="הוספה למועדפים" />
                                </div>
                                <span>
                                    הוספה למועדפים
                                </span>
                            </span>
                        </li>
                        <li>
                            <span className="fav" onClick={this.handleSendEmail.bind(this)}>
                                <div>
                                    <img src="/Content/Resources/General/sendEmail.svg" alt="שלח במייל" />
                                </div>
                                <span>
                                    שלח במייל
                                </span>
                            </span>
                        </li>
                        <li>
                            <a className="fav">
                                <div>
                                    <img src="/Content/Resources/General/print.svg" alt="הדפסה" />
                                </div>
                                <span>
                                    הדפסה
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}