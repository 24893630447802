import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

export default class SearchableSelect extends React.Component {

    _selectedOption = null;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            //selectedOption: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }
    
    //static getDerivedStateFromProps(nextProps, prevState) {
    //    console.log('getDerivedStateFromProps');

    //    const selectedOption = _.unionWith(prevState.selectedOption, nextProps.selectedOption, (a, b) => a.typeValue === b.typeValue && a.value === b.value);

    //    return { selectedOption: selectedOption };
    //}

    //componentDidUpdate(prevProps, prevState) {
    //    console.log('componentDidUpdate');

    //    if (!Object.keys(prevProps.tokens).every(x =>
    //        _.isEqual(_.sortBy(prevProps.tokens[x]), _.sortBy(this.props.tokens[x])))) {
    //        this._selectedOption = _.unionWith(prevState.selectedOption, nextProps.selectedOption, (a, b) => a.typeValue === b.typeValue && a.value === b.value);
    //    }
    //}

    handleChange(selectedOption) {
        //this.setState({ selectedOption });
        //this._selectedOption = selectedOption;

        this.props.handleSelectChange(selectedOption != null ? selectedOption : []);
    }

    handleInputChange(newValue) {
        const inputValue = newValue.replace(/[^\p{L}\d ]/gu, '');
        this.setState({ inputValue });
        return inputValue;
    }

    loadOptions(inputValue, callback) {
        if (!inputValue) {
            return callback([]);
        }

        const { searchUrl } = this.props;

        fetch(searchUrl + '?q=' + inputValue)
            .then(response => response.json())
            .then(
                data => { callback(data); },
                error => {
                    this.setState({ error });
                });
    }

    render() {
        const { defaultOptions, defaultValue, selectedOption, placeholder, inputId } = this.props;
        //this._selectedOption = _.unionWith(this._selectedOption, selectedOption, (a, b) => a.typeValue === b.typeValue && a.value == b.value);
        //const { selectedOption } = this.state;

        const MultiValueContainer = ({ children, ...props }) => {
            return (
                components.MultiValueContainer && (
                    <components.MultiValueContainer {...props}>
                        {!!children && (
                            <i
                                className="fa fa-search"
                                aria-hidden="true"
                                style={{ position: 'absolute', left: 6 }}
                            />
                        )}
                        {children}
                    </components.MultiValueContainer>
                )
            );
        };

        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <i className="fa fa-search" aria-hidden="true" />
                    </components.DropdownIndicator>
                )
            );
        };

        const formatOptionLabel = ({ value, label, data }) => (
            <div style={{ display: "flex" }}>
                <div>{label}</div>
                <div style={{ marginRight: "10px", color: "#CCC" }}>
                    {data.name}
                </div>
            </div>
        );

        const Option = ({ children, ...props }) => {
            return (
                <components.Option {...props}>
                    <div style={{ display: "flex" }}>
                        {children}
                        <span style={{ marginRight: "10px", color: "#CCC" }}>{props.data.typeLabel}</span>
                    </div>
                </components.Option>
            );
        };

        const customStyles = {
            control: (base) => ({
                ...base,
                flexDirection: "row-reverse"
            })
        };

        return (
            <AsyncSelect
                inputId={inputId}
                closeMenuOnSelect={false}
                isClearable={false}
                noOptionsMessage={() => null}
                styles={customStyles}
                components={{ Option, DropdownIndicator, IndicatorSeparator: () => null }}
                isMulti
                cacheOptions
                value={/*this._selectedOption*/selectedOption}
                //formatOptionLabel={formatOptionLabel}
                loadOptions={this.loadOptions}
                defaultOptions={defaultOptions}
                defaultValue={defaultValue}
                placeholder={placeholder}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
            />
        );
    }
}