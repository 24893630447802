import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';

import Tender from './Tender.jsx';
import TenderReminder from './TenderReminder.jsx';

export default class TenderList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            tenders: [],
            checkedItems: new Set(),
            totalCount: 0,
            loading: true,
            showEditReminder: false,
            reminderTenderId: 0,
            hasMoreResults: true,
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleAddToFavorite = this.handleAddToFavorite.bind(this);
        this.handleAddOrUpdateReminder = this.handleAddOrUpdateReminder.bind(this);

        this.resultsPage = 1;
        this.resultsPageSize = 10;
        this.resultsLoading = false;
    }

    componentDidMount() {
        console.log('TenderList: componentDidMount');
        //this.fetchTenders();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('TenderList: componentDidUpdate');
        //if (!Object.keys(prevProps.tokens).every(x =>
        //    _.isEqual(_.sortBy(prevProps.tokens[x]), _.sortBy(this.props.tokens[x])))) {
        //    this.fetchTenders();
        //}
        if (!_.isEqual(this.props.tokens, prevProps.tokens) ||
            !_.isEqual(this.props.filters, prevProps.filters) ||
            this.props.filterFromDate !== prevProps.filterFromDate ||
            this.props.filterToDate !== prevProps.filterToDate ||
            this.props.filterDateType !== prevProps.filterDateType ||
            this.props.filterDefenceMinistry !== prevProps.filterDefenceMinistry ||
            this.props.IsSupplierRegistration !== prevProps.IsSupplierRegistration ||
            this.props.IsAuctionResults !== prevProps.IsAuctionResults ||
            this.props.IsFreeAuction !== prevProps.IsFreeAuction ||
            this.props.IsTourMandatory !== prevProps.IsTourMandatory ||
            this.props.IsGeneral !== prevProps.IsGeneral ) {
            this.resultsPage = 1;
            this.fetchTenders();
        }
    }

    fetchTenders() {
        const { listUrl, isFavorites, tokens, filters, filterFromDate, filterToDate, filterDateType, filterDefenceMinistry,
            IsSupplierRegistration, IsAuctionResults, IsFreeAuction, IsGeneral, IsTourMandatory } = this.props;

        let query = tokens.map(x => `token.${x.typeValue}=${x.value}`).concat(filters.map(x => `filter.${x.type}=${x.id}`));
        query.push(`filter.fromDate=${filterFromDate}`);
        query.push(`filter.toDate=${filterToDate}`);
        query.push(`filter.dateType=${filterDateType}`);
        query.push(`filter.filterDefenceMinistry=${filterDefenceMinistry}`);
        query.push(`filter.IsSupplierRegistration=${IsSupplierRegistration}`);
        query.push(`filter.IsAuctionResults=${IsAuctionResults}`);
        query.push(`filter.IsFreeAuction=${IsFreeAuction}`);
        query.push(`filter.IsTourMandatory=${IsTourMandatory}`);
        query.push(`filter.IsGeneral=${IsGeneral}`);
        if (isFavorites != null) {
            query.push(`favorites=${isFavorites}`);
        }
        query.push(`page=${this.resultsPage}`);
        query.push(`pageSize=${this.resultsPageSize}`);

        fetch(listUrl + '?' + query.join('&'))
            .then(response => response.json())
            .then(
                data => {
                    this.resultsPage++;
                    this.setState((prevState, props) => ({
                        tenders: data.tenders,
                        totalCount: data.totalCount,
                        checkedItems: new Set(),
                        loading: false,
                        hasMoreResults: props.isPagging ? (this.resultsPage * this.resultsPageSize < data.totalCount) : false
                    }), () => {
                        this.props.handleChooseTenders([...this.state.checkedItems]);
                        this.resultsLoading = false;
                    });
                },
                error => {
                    this.setState({
                        error,
                        loading: false
                    });
                });
    }

    loadMore(page) {
        if (!this.resultsLoading) {
            this.resultsLoading = true;
            const { listUrl, isFavorites, tokens, filters, filterFromDate, filterToDate, filterDateType, filterDefenceMinistry,
                IsSupplierRegistration, IsAuctionResults, IsFreeAuction, IsGeneral, IsTourMandatory            } = this.props;

            let query = tokens.map(x => `token.${x.typeValue}=${x.value}`).concat(filters.map(x => `filter.${x.type}=${x.id}`));
            query.push(`filter.fromDate=${filterFromDate}`);
            query.push(`filter.toDate=${filterToDate}`);
            query.push(`filter.dateType=${filterDateType}`);
            query.push(`filter.filterDefenceMinistry=${filterDefenceMinistry}`);
            query.push(`filter.IsSupplierRegistration=${IsSupplierRegistration}`);
            query.push(`filter.IsAuctionResults=${IsAuctionResults}`);
            query.push(`filter.IsFreeAuction=${IsFreeAuction}`); 
            query.push(`filter.IsTourMandatory=${IsTourMandatory}`); 
            query.push(`filter.IsGeneral=${IsGeneral}`);
            if (isFavorites != null) {
                query.push(`favorites=${isFavorites}`);
            }
            query.push(`page=${this.resultsPage}`);
            query.push(`pageSize=${this.resultsPageSize}`);

            fetch(listUrl + '?' + query.join('&'))
                .then(response => response.json())
                .then(
                    data => {
                        this.resultsPage++;
                        this.setState((prevState, props) => ({
                            tenders: [...prevState.tenders, ...data.tenders],
                            totalCount: data.totalCount,
                            hasMoreResults: props.isPagging ? (this.resultsPage * this.resultsPageSize < data.totalCount) : false
                        }), () => {
                            this.resultsLoading = false;
                        });
                    },
                    error => {
                        this.setState({
                            error,
                            loading: false
                        });
                    });
        }
    }

    handleCheckboxChange(e) {
        const checked = e.target.checked;
        const value = Number(e.target.value);

        if (checked === true) {
            this.setState((prevState, props) => ({
                checkedItems: prevState.checkedItems.add(value)
            }), () => { this.props.handleChooseTenders([...this.state.checkedItems]); });
        } else {
            this.setState((prevState, props) => {
                prevState.checkedItems.delete(value);
                return {
                    checkedItems: prevState.checkedItems
                };
            }, () => { this.props.handleChooseTenders([...this.state.checkedItems]); });
        }
    }

    handleAddToFavorite(addToFavorites, clearCheckedItems = false) {
        const { addToFavoriteUrl } = this.props;
        //const query = addToFavorites.map((x, i) => `favorites[${i}].Key=${x.id}&favorites[${i}].Value=${x.isFavorite}`).join('&');
        let data = {};
        for (const addToFavorite of addToFavorites) {
            data[addToFavorite.id] = addToFavorite.isFavorite;
        }

        fetch(addToFavoriteUrl, {
            method: 'POST',
            //headers: {'Content-Type': 'application/x-www-form-url-encoded', 'Accept': 'application/json'},
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    const newState = clearCheckedItems ? { checkedItems: new Set() } : {};
                    this.setState((prevState, props) => (Object.assign(newState, {
                        tenders: prevState.tenders.map(el => el.id in data ? { ...el, isFavorite: data[el.id] } : el),
                    })), () => { this.props.handleChooseTenders([...this.state.checkedItems]); });
                }
            })
            .catch(error => { console.log(data) });
    }

    handleCloseEditReminderClick(e) {
        this.setState({
            showEditReminder: false
        });
    }

    handleEditReminderClick(id) {
        this.setState({
            showEditReminder: true,
            reminderTenderId: id
        });
    }

    handleAddOrUpdateReminder(reminder) {
        const { addOrUpdateReminderUrl } = this.props;

        fetch(addOrUpdateReminderUrl, {
            method: 'POST',
            //headers: {'Content-Type': 'application/x-www-form-url-encoded', 'Accept': 'application/json'},
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(reminder)
        })
            .then(response => {
                if (response.ok) {
                    this.setState((prevState, props) => ({
                        tenders: prevState.tenders.map(el => el.id === reminder.tenderId ? { ...el, hasReminder: true } : el),
                        showEditReminder: false
                    }));
                }
            })
            .catch(error => { console.log(error) });
    }

    renderTenderList(tenders) {
        return tenders.map((tender, index) =>
            <Tender
                key={tender.id}
                {...tender}
                handleAddToFavorite={this.handleAddToFavorite}
                handleAddOrUpdateReminder={this.handleAddOrUpdateReminder}
                handleCheckboxChangeClick={this.handleCheckboxChange}
                handleEditReminderClick={this.handleEditReminderClick.bind(this)}
                isChecked={this.state.checkedItems.has(tender.id)}
                showBanner={index % 15 == 14}

            />
        );
    }

    render() {
        const contents = this.renderTenderList(this.state.tenders);

        const tenderReminder = this.state.showEditReminder ?
            <TenderReminder
                tenderId={this.state.reminderTenderId}
                tenderReminderUrl={this.props.tenderReminderUrl}
                handleCloseClick={this.handleCloseEditReminderClick.bind(this)}
                handleAddOrUpdateReminder={this.handleAddOrUpdateReminder.bind(this)} />
            : null;

        //       const item =  if (this.state.totalCount != 0) {
        //           `<h2 className="tenders_headline1">
        //    נמצאו <strong>`+ this.state.totalCount + `</strong> מכרזים רלוונטים עבורך
        //</h2>`};
        //tenders.length == 0
        //    ? <p>אין תוצאות להצגה</p>
        return (
            <div>
                {this.state.totalCount ? (
                    <h2 className="tenders_headline1">
                        נמצאו <strong>{this.state.totalCount}</strong> מכרזים רלוונטים עבורך
                    </h2>
                ) : null}
                <div className="tenders_ul">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMore.bind(this)}
                        hasMore={this.state.hasMoreResults}
                        loader={<div className="loader" key={0}>Loading...</div>}
                    >
                        {contents}
                    </InfiniteScroll>
                </div>
                {tenderReminder}
            </div>
        );
    }
}