import React from 'react';

import AuctionItem from './AuctionItem.jsx';
import AuctionActions from './AuctionActions.jsx';
import Reminder from './Reminder.jsx'

export default class Auctions extends React.Component {
    constructor(props) {
        super(props);

        let auctions = {};
        for (var i = 0; i < props.auctions.length; i++) {
            auctions[props.auctions[i].Id] = {
                favorite: props.auctions[i].IsFavorite,
                checked: false,
                reminder: props.auctions[i].HasReminder
            };
        }      

        let fields = { auctionId: "", title: "", reminderDate: "", reminderTime: "", email: "", phone: "", comment: "" };

        this.state = {
            auctions: auctions,
            reminder: { visible: false, fields: fields }
        };

        this.checkboxHandle = this.checkboxHandle.bind(this);
        this.addToFavorites = this.addToFavorites.bind(this);
        this.addItemToFavorites = this.addItemToFavorites.bind(this);
        this.sendEmails = this.sendEmails.bind(this);
        this.showReminderForm = this.showReminderForm.bind(this);
        this.hideReminderPopup = this.hideReminderPopup.bind(this);
        this.addReminder = this.addReminder.bind(this);
    }

    // add reminder
    addReminder() {
        let formData = new FormData();

        let fields = this.state.reminder.fields;
        Object.keys(fields).forEach(key => { formData.append(key, fields[key]); });

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch(this.props.reminderUrl, requestOptions)
            .then(response => response.json())
            .then(
                (data) => {
                    this.setState(function (previousState, currentProps) {
                        var _auctions = { ...previousState.auctions };
                        var _reminder = { ...previousState.reminder };
                        _auctions[fields.auctionId].reminder = true;
                        _reminder.visible = false;

                        return {
                            auctions: _auctions,
                            reminder: _reminder
                        };
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                });
    }

    // show reminer popup
    showReminderForm(auctionId) {
        fetch(this.props.getReminderUrl + '?id=' + auctionId)
            .then(response => response.json())
            .then(
                (data) => {
                    this.setState(function (previousState, currentProps) {
                        var _reminder = { ...previousState.reminder };
                        _reminder.visible = true;
                        _reminder.fields = data;
                        return {
                            reminder: _reminder
                        };
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                });
    };

    // hide reminder popup
    hideReminderPopup() {
        this.setState(function (previousState, currentProps) {
            var _reminder = { ...previousState.reminder };
            _reminder.visible = false;
            return {
                reminder: _reminder
            };
        });
    };

    // checkbox on click
    checkboxHandle(isChecked, auctionId) {
        this.setState(function (previousState, currentProps) {
            var _auctions = { ...previousState.auctions };
            _auctions[auctionId].checked = isChecked;
            return {
                auctions: _auctions
            };
        });
    };

    // add item to favorites
    addItemToFavorites(id) {
        let formData = new FormData();
        formData.append("ids", id);

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch(this.props.favoriteUrl, requestOptions)
            .then(response => response.json())
            .then(
                (data) => {
                    this.setState(function (previousState, currentProps) {
                        var _auctions = { ...previousState.auctions };
                        _auctions[id].favorite = !previousState.auctions[id].favorite;
                        return {
                            auctions: _auctions
                        };
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                });
    }  

    // add items to favorites from AuctionActions
    addToFavorites(e) {
        e.preventDefault();
        let formData = new FormData();
        Object.keys(this.state.auctions).filter(x => this.state.auctions[x].checked).forEach(x => { formData.append("ids", x) });

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch(this.props.favoriteUrl, requestOptions)
            .then(response => response.json())
            .then(
                (data) => {
                    this.setState(function (previousState, currentProps) {
                        var _auctions = { ...previousState.auctions };
                        Object.keys(previousState.auctions).filter(x => previousState.auctions[x].checked).forEach(x => _auctions[x].favorite = !previousState.auctions[x].favorite);
                        return {
                            auctions: _auctions
                        };
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                });
    }   

    // send items to Email from AuctionActions
    sendEmails(e) {
        e.preventDefault();
        let formData = new FormData();
        Object.keys(this.state.auctions).filter(x => this.state.auctions[x].checked).forEach(x => { formData.append("ids", x) });

        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch(this.props.EmailUrl, requestOptions)
            .then(response => response.json())
            .then(
                (data) => {
                    this.setState(function (previousState, currentProps) {
                        
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                });
    }

    render() {
        return (
            <div>
                <ul className="tenders_ul">
                    {this.props.auctions.map((item, index) => (
                        <li key={index}>
                            <AuctionItem item={item}
                                auctionUrl={this.props.auctionUrl}
                                checkboxHandle={this.checkboxHandle}
                                showReminderForm={this.showReminderForm}
                                favorite={this.state.auctions[item.Id].favorite}
                                reminder={this.state.auctions[item.Id].reminder}
                                isChecked={this.state.auctions[item.Id].checked}
                                addItemToFavorites={this.addItemToFavorites}
                                isLoggedIn={this.props.isLoggedIn}
                            />
                        </li>
                    ))}
                </ul>
                <AuctionActions
                    auctions={Object.values(this.state.auctions).filter(auction => auction.checked)}
                    addToFavorites={this.addToFavorites}
                    sendEmails={this.sendEmails}
                    isLoggedIn={this.props.isLoggedIn}
                />
                <Reminder
                    addReminder={this.addReminder}
                    reminder={this.state.reminder}
                    hideReminderPopup={this.hideReminderPopup}
                />
            </div>
        );
    }
};