import React from 'react';
import _ from 'lodash';

export default class PublisherFilter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            items: [],
            checkedItems: new Set(),
            loading: true,
            searchString: ''
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearAllClick1 = this.handleClearAllClick1.bind(this);
    }

    componentDidMount() {
        console.log('PublisherFilter: componentDidMount');

        const { items, checkedItems, tokens } = this.props;
        //const _checkedItems = tokens.filter(x => x.typeValue === 'publisher').map(x => x.value);
        
        this.setState({
            items: items,
            checkedItems: new Set(checkedItems),
            loading: false
        });
    }

    handleCheckboxChange(e) {
        const checked = e.target.checked;
        const value = e.target.value;

        if (checked === true) {
            this.setState((prevState, props) => ({
                checkedItems: prevState.checkedItems.add(value)
            }));
        } else {
            this.setState((prevState, props) => {
                prevState.checkedItems.delete(value);
                return {
                    checkedItems: prevState.checkedItems
                };
            });
        }
    }

    handleSearchChange(e) {
        this.setState({
            searchString: e.target.value,
        });
    }

    handleClearAllClick1(e) {
        this.setState({
            checkedItems: new Set()
        });
    }

    handleSubmitClick(e) {
        const { items } = this.props;

        const checkedItems = items.filter(x => this.state.checkedItems.has(x.id));
        this.props.onSelectItems(checkedItems);
    }

    renderItemList(items) {
        return <ul className="tenders_ul">
            {items.map(item =>
                <li key={item.id}>
                    <div className="checkbox_conti">
                        <div>
                            <input
                                type="checkbox"
                                value={item.id}
                                checked={this.state.checkedItems.has(item.id)}
                                onChange={this.handleCheckboxChange} />
                            <span></span>
                        </div>
                        <label>{item.name}</label>
                    </div> 
                </li>
            )}
        </ul>;
    }

    render() {
        const { items } = this.props;

        let contents = this.state.loading
            ? <p>Loading...</p>
            : this.renderItemList(items.filter(x => x.name.includes(this.state.searchString)));

        return (
            <div className="popup_conti choose_option_popup">
                <div className="popup form">
                    <a className="close_popup" onClick={this.props.onCloseClick}>X</a>
                    <div className="popup_quick_options">
                        <ul className="flex flex_end">
                            <li>
                                <span onClick={this.handleClearAllClick1}>נקה הכל</span>
                            </li>
                        </ul>
                    </div>
                    <div className="form">
                        <div className="popup_filter">
                            <ul>
                                <li>
                                    <input type="text" onChange={this.handleSearchChange} placeholder="סנן" />
                                </li>
                            </ul>
                        </div>
                        <div className="popup_items">
                            {contents}
                        </div>
                        <div className="choosen">
                            נבחרו <span>{this.state.checkedItems.size}</span> פריטים
                        </div>
                        <div className="flex popup_buttons">
                            <ul className="flex flex_space_evenly flex_items_align_middle flex_wrap">
                                <li>
                                    <button role="button" className="transition" onClick={this.props.onCloseClick}>
                                        בטל
                                    </button>
                                </li>
                                <li>
                                    <button role="button" className="button button2 transition" onClick={this.handleSubmitClick.bind(this)}>
                                        בחר
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}