import React from 'react';
import { Bar } from 'react-chartjs-2';

import TenderList from './TenderList.jsx';
import Toolbar from './Toolbar.jsx';

export default class StaticSearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            tokens: [...props.defaultOptions],
            filters: [],
            filterFromDate: null,
            filterToDate: null,
            filterDateType: 0,
            checkedTenders: []
        };

        this.tenderList = null;

        this.setTenderListRef = element => {
            this.tenderList = element;
        };

        const months = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];
        const d = new Date();
        let totalRuns = months.length - 1;
        let monthsArray = [];
        for (var i = d.getMonth(); i > -totalRuns; i--) {
            if (i < 0) {
                i = months.length-1;
            }
            
            monthsArray.push(months[i]);
            totalRuns--
            if (totalRuns < 0) {
                break;
            }
        }

        this.monthsArray = monthsArray.reverse();
    }

    componentDidMount() {
        console.log('Search: componentDidMount');
        
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('Search: componentDidUpdate');
    }

    handleChooseTenders(checkedTenders) {
        this.setState({ checkedTenders: checkedTenders })
    }

    handleAddCheckedToFavorite() {
        this.tenderList.handleAddToFavorite(this.state.checkedTenders.map(el => ({ id: el, isFavorite: true })), true);
    }

    render() {       
        const data = {
            labels: this.monthsArray,
            datasets: [{
                data: this.props.chartData,
                backgroundColor: ['#F9E12C'],
                borderWidth: 0
            }]
        };

        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            },
            responsive: true
        };

        const { showChart, defaultOptions } = this.props;
        const [token] = defaultOptions;
        const verticalBar = showChart
            ? (<div className="chart_conti">
                <h2>
                    סטטיסטיקת פרסום מכרזים בענף {token.label} בשנה האחרונה
                </h2>
                <Bar data={data} options={options} />
               </div>)
            : null;
        return (
            <>
                {verticalBar}
                <TenderList
                    listUrl={this.props.tenderlistUrl}
                    isFavorites={this.props.isFavorites}
                    addToFavoriteUrl={this.props.addToFavoriteUrl}
                    addOrUpdateReminderUrl={this.props.addOrUpdateReminderUrl}
                    tenderReminderUrl={this.props.tenderReminderUrl}
                    tokens={this.state.tokens}
                    filters={this.state.filters}
                    filterFromDate={this.state.filterFromDate}
                    filterToDate={this.state.filterToDate}
                    filterDateType={this.state.filterDateType}
                    handleChooseTenders={this.handleChooseTenders.bind(this)}
                    isPagging={this.props.isPagging}
                    ref={this.setTenderListRef}/>

                <Toolbar
                    sendEmailUrl={this.props.sendEmailUrl}
                    userEmail={this.props.userEmail}
                    checkedTenders={this.state.checkedTenders}
                    handleAddCheckedToFavorite={this.handleAddCheckedToFavorite.bind(this)} />
            </>
        );
    }
}