import React from 'react';
import _ from 'lodash';
import TenderReminder from './TenderReminder.jsx';


export default class TendePageReminder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            //tenders: [],
            loading: true,
            showEditReminder: false,
            hasReminder: this.props.hasReminder,
            reminderTenderId: 0,
        };
        this.handleAddOrUpdateReminder = this.handleAddOrUpdateReminder.bind(this);
    }

    handleCloseEditReminderClick(e) {
        this.setState({
            showEditReminder: false
        });
    }

    handleEditReminderClick(id) {
        this.setState({
            showEditReminder: true,
            reminderTenderId: id
        });
    }

    handleAddOrUpdateReminder(reminder) {
        const { addOrUpdateReminderUrl } = this.props;

        fetch(addOrUpdateReminderUrl, {
            method: 'POST',
            //headers: {'Content-Type': 'application/x-www-form-url-encoded', 'Accept': 'application/json'},
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(reminder)
        })
            .then(response => {
                if (response.ok) {
                    this.setState((prevState, props) => ({
                        //tenders: prevState.tenders.map(el => el.id === reminder.tenderId ? { ...el, hasReminder: true } : el),
                        showEditReminder: false,
                        hasReminder: true
                    }));
                }
            })
            .catch(error => { console.log(error) });
    }

    render() {
        const tenderReminder = this.state.showEditReminder ?
            <TenderReminder
                tenderId={this.state.reminderTenderId}
                tenderReminderUrl={this.props.tenderReminderUrl}
                handleCloseClick={this.handleCloseEditReminderClick.bind(this)}
                handleAddOrUpdateReminder={this.handleAddOrUpdateReminder.bind(this)} />
            : null;

        return (<div>
            <a className={`reminder ${this.state.hasReminder ? ' active' : ''}`} onClick={() => this.handleEditReminderClick(this.props.id)}>
                <div>
                    <img src="/Content/Resources/TenderIcons/bell.svg" className="bell transition" alt="הוספת תזכורת" />
                    <img src="/Content/Resources/TenderIcons/bellActive.svg" className="bell_active transition" alt="הוספת תזכורת" />
                </div>
                <span>
                    הוספת תזכורת
                </span>
            </a>
            {tenderReminder}
        </div>
        )
    }
}