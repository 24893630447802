import React from 'react';

export default class AuctionFavorite extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <li>
                <a href="#" className={this.props.favorite ? "fav active" : "fav"} onClick={(e) => { e.preventDefault(); this.props.isLoggedIn ? this.props.addItemToFavorites(this.props.itemId) : showNotLoggedInPopup() }}>
                    <div>
                        <img src="/Content/Resources/TenderIcons/star.svg" alt="הוספה למועדפים" className="star transition" />
                        <img src="/Content/Resources/TenderIcons/starActive.svg" alt="הוספה למועדפים" className="star_active transition" />
                    </div>
                    <span>
                        הוספה למועדפים
                    </span>
                </a>
            </li>
        );
    };
};