import React from 'react';
import _ from 'lodash';

export default class SubSearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            items: [],
            checkedItems: new Set(),
            loading: true,
            searchString: ''
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidMount() {
        console.log('TenderList: componentDidMount');

        const { listUrl } = this.props;

        fetch(listUrl)
            .then(response => response.json())
            .then(
                data => {
                    this.setState({
                        items: data,
                        checkedItems: new Set(this.props.checkedItems),
                        error: null,
                        loading: false
                    });
                },
                error => {
                    this.setState({
                        error,
                        loading: false
                    });
                });
    }

    //componentDidUpdate(prevProps, prevState) {
    //    console.log('componentDidUpdate');

    //    const checkedItems = new Set(this.props.checkedItems);

    //    if (!_.isEqual(_.sortBy([...prevState.checkedItems]), _.sortBy(this.props.checkedItems))) {
    //        this.setState((prevState, props) => ({
    //            checkedItems: checkedItems
    //        }));
    //    }
    //}

    handleCheckboxChange(e) {
        const checked = e.target.checked;
        const value = e.target.value;

        if (checked === true) {
            this.setState((prevState, props) => ({
                checkedItems: prevState.checkedItems.add(value)
            }));
        } else {
            this.setState((prevState, props) => {
                prevState.checkedItems.delete(value);
                return {
                    checkedItems: prevState.checkedItems
                };
            });
        }
    }

    handleSearchChange(e) {
        this.setState({
            searchString: e.target.value,
        });
    }

    handleSelectAllClick(e) {
        this.setState((state, props) => ({
            checkedItems: new Set(state.items.map(x => x.id))
        }));
    }

    handleClearAllClick(e) {
        this.setState({
            checkedItems: new Set()
        });
    }

    handleSubmitClick(e) {
        const checkedItems = this.state.items.filter(x => this.state.checkedItems.has(x.id));
        this.props.onSelectItems(checkedItems);
    }

    renderItemList(items) {
        return <ul className="tenders_ul">
            {items.map(item =>
                <li key={item.id}>
                    <div className="checkbox_conti">
                        <div>
                            <input
                                type="checkbox"
                                value={item.id}
                                checked={this.state.checkedItems.has(item.id)}
                                onChange={this.handleCheckboxChange} />
                            <span></span>
                        </div>
                        <label>{item.name}</label>
                    </div>
                </li>
            )}
        </ul>;
    }

    render() {
        let contents = this.state.loading
            ? <p>Loading...</p>
            : this.renderItemList(this.state.items.filter(x => x.name.includes(this.state.searchString)));

        return (
            <div className="popup_conti choose_option_popup">
                <div className="popup form">
                    <a className="close_popup" onClick={this.props.onCloseClick}>X</a>
                    <div className="general_headline5 flex flex_space_evenly item_margin_b_10">
                        <h2>{this.props.title}</h2>
                        <div className="popup_quick_options">
                            <ul className="flex flex_items_align_middle">
                                <li>
                                    <span role="button" onClick={this.handleSelectAllClick.bind(this)}>
                                        בחר הכל
                                    </span>
                                </li>
                                <li>
                                    <span role="button" onClick={this.handleClearAllClick.bind(this)}>
                                        נקה הכל
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
        
                    <div className="form">
                        <div className="popup_filter">
                            <ul>
                                <li>
                                    <input type="text" onChange={this.handleSearchChange} placeholder="סנן" />
                                </li>
                            </ul>
                        </div>
                        <div className="popup_items">
                            {contents}
                        </div>
                        <div className="choosen">
                            נבחרו <span>{this.state.checkedItems.size}</span> פריטים
                        </div>
                        <div className="flex popup_buttons">
                            <ul className="flex flex_space_evenly flex_items_align_middle flex_wrap">
                                <li>
                                    <button role="button" className="transition" onClick={this.props.onCloseClick}>
                                        בטל
                                    </button>
                                </li>
                                <li>
                                    <button role="button" className="button button2 transition" onClick={this.handleSubmitClick.bind(this)}>
                                        בחר
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}